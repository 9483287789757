import { Flex, Text } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import Loading from "../components/loading";
import { CheckCouponId } from "../firebase_functions";

const Check = () => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isSuccess, setIsSuccess ] = useState(false);
  const search = useLocation().search;
  const query = useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);

  function isSmartPhone() {
    if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
      return true;
    } else {
      return false;
    }
  }

  useState(() => {
    let cid = query.get('id');
    if (cid === null) {
      cid = ''
    }
    (async()=>{
      setIsSuccess(await CheckCouponId(cid));
      setIsLoading(true);
    })()
  })

  return (
    isLoading ? (
      <>
        <Flex h={isSmartPhone()?'85vh':'100vh'} flexFlow='column'>
          <Flex h='150px' w='100%' backgroundColor='#b0f9b0' justify='center' align='center' flexFlow='column'>
            <Text fontSize='2xl' align='center' color='#00b600' fontWeight='bold'>
              クーポンチェック
            </Text>
          </Flex>
          <Flex flexFlow='column' overflow='hidden' align='center' justify='center' flexGrow='1'>
            {isSuccess ? (
              <>
                <Text m='5px' align='center' fontSize='larger' color='#00b600' fontWeight='bold'>
                  クーポンを受け取りました。
                </Text>
                <Text border='2px' m='5px' p='20px 60px' borderRadius='30px' fontSize='5xl' align='center' color='#00b600' fontWeight='bold'>
                  OK
                </Text>
              </>
            ) : (
              <>
                <Text m='5px' align='center' fontSize='larger' color='#00b600' fontWeight='bold'>
                  クーポンを受け取れませんでした。使用済みであるか、IDが存在しません。
                </Text>
                <Text border='2px' m='5px' p='20px 60px' borderRadius='30px' fontSize='5xl' align='center' color='#00b600' fontWeight='bold'>
                  NG
                </Text>
              </>
            )}
          </Flex>
        </Flex>
      </>
    ):(
      <Loading/>
    )
  )
}

export default Check;
