import { Text, Flex } from "@chakra-ui/react"

const NotFound = () => {
  function isSmartPhone() {
    if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      <Flex h={isSmartPhone()?'85vh':'100vh'} flexFlow='column'>
        <Flex h='150px' w='100%' backgroundColor='#f9b0b0' justify='center' align='center' flexFlow='column'>
          <Text p='5px' fontSize='2xl' align='center' color='#b60000' fontWeight='bold'>
            Error
          </Text>
          <Text p='5px' align='center' color='#b60000' fontWeight='bold'>
            ページが存在しません。
          </Text>
        </Flex>
        <Flex overflow='hidden' flexFlow='column' align='center' justify='center' flexGrow='1'>
          <Text p='5px' fontSize='2xl' align='center' color='#b60000' fontWeight='bold'>404</Text>
          <Text p='5px' align='center' color='#b60000' fontWeight='bold'>Not found</Text>
        </Flex>
      </Flex>
    </>
  )
}

export default NotFound;
