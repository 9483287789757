import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import cred from "./.env.json";

const firebaseConfig = cred;

const app = initializeApp(firebaseConfig);

export default app;
export const auth = getAuth(app);
export const db = getDatabase(app);
