import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Root from './routes/root';
import Distribute from './routes/distribute';
import Coupon from './routes/coupon';
import NotFound from './routes/404';
import Check from './routes/check';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
    <ChakraProvider>
      <BrowserRouter>
        <Routes>
          <Route path='*' element={<NotFound/>}/>

          <Route path='/' element={<Root/>}/>
          <Route path='/distribute' element={<Distribute/>}/>
          <Route path='/coupon' element={<Coupon/>}/>
          <Route path='/check' element={<Check/>}/>
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  // </React.StrictMode>
);

reportWebVitals();
