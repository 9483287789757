import { Flex, Box, Text } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Loading from '../components/loading';
import QRCode from "qrcode.react";
import { DistributeCouponId } from '../firebase_functions';

const Coupon = () => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isSuccess, setIsSuccess ] = useState(false);
  const [ couponId, setCouponId ] = useState<string>('');
  const search = useLocation().search;
  const query = useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);

  function isSmartPhone() {
    if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    let cid = query.get('id');
    if (cid === null) {
      cid = ''
    }
    setCouponId(cid);
    (async()=>{
      setIsSuccess(await DistributeCouponId(cid));
      setIsLoading(true);
    })()
  }, [query]);

  return (
    isLoading ? (
      isSuccess ? (
        <>
          <Flex h={isSmartPhone()?'85vh':'100vh'} flexFlow='column'>
            <Flex h='150px' w='100%' backgroundColor='#f9f9b0' justify='center' align='center' flexFlow='column'>
              <Text p='5px' fontSize='2xl' align='center' color='#808000' fontWeight='bold'>
                クーポン
              </Text>
              <Text p='5px' align='center' color='#808000' fontWeight='bold'>
                担当の部員に見せてね
              </Text>
            </Flex>
            <Flex flexFlow='column' align='center' justify='center' flexGrow='1'>
              <Text fontSize='larger' color='#808000' fontWeight='bold'>クーポンID</Text>
              <Text m='5px' align='center' borderRadius='5px' bg='#f9f9b0' p='5px 10px' color='#808000' fontWeight='bold'>{couponId}</Text>
              <Box m='10px' borderRadius='5px' bg='#c0c000' p='15px'>
                <QRCode size={200} fgColor="white" bgColor="#c0c000" value={`${window.location.protocol}//${window.location.host}/check?id=${couponId}`}/>
              </Box>
            </Flex>
          </Flex>
        </>
      ) : (
        <>
          <Flex h={isSmartPhone()?'85vh':'100vh'} flexFlow='column'>
            <Flex h='150px' w='100%' backgroundColor='#f9b0b0' justify='center' align='center' flexFlow='column'>
              <Text p='5px' fontSize='2xl' align='center' color='#b60000' fontWeight='bold'>
                Error
              </Text>
              <Text p='5px' align='center' color='#b60000' fontWeight='bold'>
                このクーポンIDは存在しないか既に使用済みです。
              </Text>
            </Flex>
            <Flex overflow='hidden' flexFlow='column' align='center' justify='center' flexGrow='1'>
              <Text fontSize='larger' color='#b60000' fontWeight='bold'>クーポンID</Text>
              {couponId.length !== 0 ? (
                <Text m='5px' align='center' borderRadius='5px' bg='#f9b0b0' p='5px 10px' color='#b60000' fontWeight='bold'>{couponId}</Text>
              ):(
                <Box m='5px' borderRadius='5px' bg='#f9b0b0' p='15px 8px'></Box>
              )}
            </Flex>
          </Flex>
        </>
      )
    ):(
      <Loading/>
    )
  )
}

export default Coupon;
