import { Flex, Button, Text } from '@chakra-ui/react';
import { auth } from '../firebase_config';
import { getRedirectResult, GoogleAuthProvider, signInWithRedirect, signOut } from 'firebase/auth'
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '../components/loading';

const Root = () => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isInvalidUser, setIsInvalidUser ] = useState(false);
  const navigate = useNavigate();
  const provider = new GoogleAuthProvider();
  const signInAction = () => {
    signInWithRedirect(auth, provider);
  }

  function isSmartPhone() {
    if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    getRedirectResult(auth)
    .then((result) => {
      setIsLoading(true)
      if(result !== null){
        const user = result.user;
        if(user.uid === process.env.REACT_APP_OWNER_UID) {
          navigate(`/distribute?uid=${user.uid}`)
        }
        setIsInvalidUser(true);
        signOut(auth);
      } else {
        setIsInvalidUser(false);
      }
    }).catch((error) => {
      console.error(error);
      const errorCode = error.code;
      const errorMessage = error.message;
      const email = error.email;
      console.error(errorCode);
      console.error(errorMessage);
      console.error(email);
    });    
  },[navigate]);

  return (
    isLoading ? (
      <>
        <Flex h={isSmartPhone()?'85vh':'100vh'} flexFlow='column'>
          <Flex h='150px' w='100%' backgroundColor='#b0d3f9' justify='center' align='center' flexFlow='column'>
            <Text align='center' color='#006ab6' fontWeight='bold'>
              クーポン管理サイトTOP
            </Text>
            <Text align='center' color='#006ab6' fontWeight='bold'>
              管理者は下からC3アカウントでログインしてね
            </Text>
          </Flex>
          {isInvalidUser ? 
            <Flex h='30px' w='100%' backgroundColor='#eb1616' justify='center' align='center' flexFlow='column'>
              <Text color='#f5dbdb' fontWeight='bold'>
                サインインするユーザが誤っています
              </Text>
            </Flex>
            :<></>
          }
          <Flex align='center' justify='center' flexGrow='1'>
            <Button color='white' bg='#3ea8ff' _hover={{color:'white', bg:'#3ea8ff'}} _active={{color:'#aaa', bg:'#006ab6'}} onClick={signInAction}>
              サインイン
            </Button>
          </Flex>
        </Flex>
      </>
    ):(
      <Loading/>
    )
  );
}

export default Root
