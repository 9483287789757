import { db } from "./firebase_config";
import { ref, set, get, child, update } from 'firebase/database'

function createUuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(a) {
    let r = (new Date().getTime() + Math.random() * 16)%16 | 0, v = a === 'x' ? r : ((r & 0x3) | 0x8);
    return v.toString(16);
  });
}

function CreateNewCouponData() {
  const newCouponId = createUuid();
  set(ref(db, `coupon/${newCouponId}`), {
    status: 0
  })
  return newCouponId
}

export async function GenerateNewCouponId(oldCouponId: string) {
  let isUpdated = false;
  let newCouponId = '';
  const dbRef = ref(db);
  const snapshot = await get(child(dbRef, 'coupon'))
  let couponList = snapshot.val();
  for (let key in couponList) {
    if (couponList[key].status === 0 && key !== oldCouponId) {
      newCouponId = key;
      isUpdated = true;
      break;
    }
  }
  if (!isUpdated) {
    newCouponId = CreateNewCouponData();
  }
  return newCouponId
}

function CheckWillErrorId(couponId: string) {
  const checkOutChars = ['.', '#', '$', '[', ']', ',', '/']
  for(let i = 0; i < checkOutChars.length; i++) {
    if (couponId.indexOf(checkOutChars[i]) !== -1)
      return true;
  }
  return false;
}

async function GetCouponStatus(couponId: string) {
  if (couponId.length === 0 || CheckWillErrorId(couponId))
    return -1;

  const dbRef = ref(db);  
  const snapshot = await get(child(dbRef, `coupon/${couponId}`))
  let coupon = snapshot.val();
  if (coupon === null)
    return -1;
  const status: number|undefined = coupon.status;
  if (status === undefined)
    return -1;
  return status;
}

export async function DistributeCouponId(couponId: string) {
  const status = await GetCouponStatus(couponId);
  if (status === -1 || status === 2)
    return false;
  if (status === 1)
    return true;
  const updates: any = {};
  updates[`/coupon/${couponId}`] = {
    'status': 1
  };
  update(ref(db), updates);
  return true;
}

export async function CheckCouponId(couponId: string) {
  const status = await GetCouponStatus(couponId);
  if (status === 1) {
    const updates: any = {};
    updates[`/coupon/${couponId}`] = {
      'status': 2
    };
    update(ref(db), updates);
    return true;
  } else {
    return false;
  }
}
