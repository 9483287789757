import { Flex, Image, usePrefersReducedMotion, keyframes, Text } from '@chakra-ui/react'

const Loading = () => {
  const spin = keyframes`
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  `

  const animCheck = usePrefersReducedMotion()
  const rotate = animCheck ? undefined : `${spin} infinite 1s linear`

  return (
    <>
      <Flex h='100vh' flexFlow='column' bg='#3ea8ff'>
        <Flex align='center' justify='center' flexGrow='1' flexFlow='column'>
          <Image height='200px' src={`${process.env.PUBLIC_URL}/c3.png`} animation={rotate}/>
          <Text align='center' fontSize='2xl' m='10' color='white' fontWeight='bold'>ろーでぃんぐなう…</Text>
        </Flex>
      </Flex>
    </>
  )
}

export default Loading
