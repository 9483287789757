import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../components/loading";
import { Flex, Text, Box, Button } from '@chakra-ui/react';
import QRCode from "qrcode.react";
import { GenerateNewCouponId } from "../firebase_functions";

const Distribute = () => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ qrUrl, setQrUrl ] = useState('');
  const couponIdRef = useRef('');

  const navigate = useNavigate();
  const search = useLocation().search;
  const query = useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);

  const updateQR = useCallback(async () => {
    const newCouponId = await GenerateNewCouponId(couponIdRef.current);
    couponIdRef.current = newCouponId;
    const url = `${window.location.protocol}//${window.location.host}/coupon?id=${newCouponId}`;
    setQrUrl(url);
    setIsLoading(true);
  }, []);

  function isSmartPhone() {
    if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    const uid = query.get('uid');
    if (uid !== process.env.REACT_APP_OWNER_UID) {
      navigate('/')
    }
  }, [navigate, query]);

  useEffect(() => {
    (async () => {await updateQR()})();
    setInterval(async () => {
      await updateQR()
    }, 15000);
  }, [updateQR]);

  return (
    isLoading ? (
      <>
        <Flex h={isSmartPhone()?'85vh':'100vh'} flexFlow='column'>
          <Flex h='150px' w='100%' backgroundColor='#b0d3f9' justify='center' align='center' flexFlow='column'>
            <Text align='center' color='#006ab6' fontWeight='bold'>
              下のQRコードを読み込んでクーポンをGET!
            </Text>
          </Flex>
          <Flex align='center' justify='center' flexFlow='column' flexGrow='1'>
            <Box m='5px' borderRadius='5px' bg='#006ab6' p='15px' onClick={updateQR}>
              <QRCode size={200} fgColor="white" bgColor="#006ab6" value={qrUrl}/>
            </Box>
          </Flex>
        </Flex>
      </>
    ):(
      <Loading/>
    )
  )
}

export default Distribute;
